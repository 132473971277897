<template>
  <div class="form-wrapper">
    <slot />
  </div>
</template>

<script>
import axios from "@/helpers/axios";
export default {
  name: "FormLayout",
  props: [],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" >
.form-wrapper {
  max-width: 40em;
  margin: 0 auto;
  padding: 0;
  .contact-form {
    width: 100%;
    margin: 0 auto;
    .form-group {
      width: 100%;
      label {
        display: block;
        font-size: 0.875rem;
        font-weight: bold;
        text-align: left;
        color: white;
      }
      .error-state {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #d92121;
        margin-top: 10px;
      }
      input,
      textarea,
      select {
        width: 100%;
        min-height: 50px;
        padding: 16px;
        color: white;
        border: 1px solid #696969;
        border-radius: 2px;
        background: transparent;
        background: #262626;
      }
      textarea.form-control {
        height: 168px;
      }
      .btn-default {
        display: block;
        margin: 0 auto;
        margin-top: 32px;
        font-weight: 700;
        font-size: 1rem;
        line-height: 24px;
      }
    }
  }
  .submit-wrapper {
    P {
      color: #d92121;
      text-align: center;
    }
  }
  .contact-form--thanks {
    max-width: 588px;
    padding: 80px 0;
    text-align: center;
    margin: 0 auto;
    @include tablet-down {
      padding: 40px 0;
    }
    h6 {
      font-weight: 400;
      font-size: 1rem;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      margin-top: 16px;
    }
    p {
      font-weight: 500;
      font-size: 1rem;
      line-height: 24px;
      text-align: center;
      color: #a4aaae;
      margin-top: 16px;
    }
  }
}
</style>
