<template>
  <SiteMain>
    <PageLayout container>
      <header class="page-header">
        <h2>{{ page.title }}</h2>
        <p v-html="page.introduction"></p>
      </header>
      <section class="page-content--block">
        <div class="block-wrapper">
          <FormLayout>
            <ContactForm/>
          </FormLayout>
        </div>
      </section>
    </PageLayout>
    <CtaBlock v-if="cta != null" :title="cta.title" :text="cta.text" :buttonText="cta.button_text"
              :buttonLink="cta.button_url"/>
  </SiteMain>
</template>

<script>
import SiteMain from "@/components/SiteMain.vue";
import PageLayout from "@/components/PageLayout.vue";
import FormLayout from "@/components/FormLayout.vue";
import ContactForm from "@/components/ContactForm.vue";
import CtaBlock from "../components/CtaBlock";

export default {
  components: {
    SiteMain,
    PageLayout,
    FormLayout,
    ContactForm,
    CtaBlock,
  },
  data() {
    return {};
  },
  computed: {
    page() {
      return this.$store.state.cache.data[`contact${this.$i18n.locale}`]?.page ?? '';
    },
    cta() {
      const page = this.$store.state.cache.data[`contact${this.$i18n.locale}`]?.page;
      return page?.cta?.length > 0 ? page.cta[0] : null;
    }
  },
  methods: {
    async loadPage() {
      const key = `contact${this.$i18n.locale}`;
      const url = `${process.env.VUE_APP_API_URL}/api/pages/get?locale=${this.$i18n.locale}&page=contact`;
      this.$store.commit('cacheRequest', {key, url});
    },
  },
  created() {
    this.loadPage();
  },
  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadPage();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-layout {
  .page-header {
    max-width: 40em;
    margin: 0 auto;
    padding-bottom: 40px;

    p {
      color: white;
    }
  }

  .page-content--block {
    .block-wrapper {
      padding: 0;
    }
  }
}
</style>
